import React from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';

export type TNotification = {
    text: string;
    type: 'primary' | 'secondary';
};
export const NotificationWrap = styled.div<{ multiple?: boolean }>(({ multiple }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: multiple ? 'space-between' : 'center',
    position: 'absolute',
    top: 7,
    [breakpoints.sm]: {
        top: -2,
    },
}));

export const Label = styled.span<Pick<TNotification, 'type'>>(
    ({ theme }) => ({
        ...theme.mixins.useTextStyle('productInfo'),
        display: 'block',
    }),
    switchProp(
        'type',
        {
            secondary: ({ theme }) => ({
                color: theme.colors.black,
            }),
            primary: ({ theme }) => ({
                color: theme.colors.onSale,
            }),
        },
        'secondary'
    )
);

export const Notifications = ({ notifications }: { notifications: TNotification[] }) => {
    return (
        <NotificationWrap multiple={notifications.length > 1}>
            {notifications.map(({ type, text }) => (
                <Label key={text} type={type}>
                    {text}
                </Label>
            ))}
        </NotificationWrap>
    );
};
